var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"id":"ticket"}},[_c('div',{staticClass:"row justify-center full-width q-pb-xl"},[_c('q-card',{staticClass:"col-xs-11 col-sm-10 col-md-5 col-lg-5 col-xl-5 full-height q-my-lg bg-principal rounded-borders"},[_c('div',{staticClass:"absolute-top row justify-end",staticStyle:{"z-index":"1"}},[_c('q-btn',{staticClass:"q-mt-md q-mr-md cursor-pointer",staticStyle:{"color":"white"},attrs:{"icon":"close","round":"","flat":"","color":"white","dense":"","size":"small"},on:{"click":function($event){return _vm.routerGo('newrequest')}}})],1),_c('div',{staticClass:"row flex-center"},[_c('div',{staticClass:"col-11 flex-center row q-pt-lg"},[_c('div',{staticClass:"col-5 items-center justify-end row"},[_c('q-icon',{staticClass:"icono_titulo_resumen",attrs:{"name":"check_circle_outline","color":"white"}})],1),_c('div',{staticClass:"col-7 items-center justify-start row"},[_c('div',{staticClass:"col-12 row justify-start"},[_c('label',{staticClass:"titulo_resumen"},[_vm._v("Solicitud Creada")])]),_c('div',{staticClass:"col-12 row justify-start"},[_c('label',{staticClass:"titulo_resumen"},[_vm._v(" N° "+_vm._s(_vm.$store.state.ticket.correlativo ? _vm.$store.state.ticket.correlativo : _vm.$store.state.ticket.id)+" ")])])])]),_c('div',{staticClass:"col-xs-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 row justify-center items-center"},[_c('q-card',{staticClass:"login-card col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 rounded-borders",staticStyle:{"margin-top":"15px !important","margin-bottom":"50px !important"}},[_c('q-toolbar',{staticStyle:{"background-color":"#E5E5E5"}},[_c('div',{staticClass:"row items-center justify-center full-width"},[_c('div',{staticClass:"margin-estado",class:'text-center titulo_card row items-center text-bold ' +
                      (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-ml-xs' : 'q-ml-lg')},[_vm._v(" Estado Actual de su Solicitud: ")])]),_c('div',{staticClass:"row col-3"},[(
                    [1, 4, 5, 8, 13, 16, 17, 18].includes(
                      _vm.$store.state.ticket.estado_ticket
                    )
                  )?_c('q-card',{staticClass:"full-width btn-detalle"},[_c('q-card-section',{staticClass:"q-pa-sm",style:({
                      'background-color': _vm.getColor(
                        _vm.$store.state.ticket.estado_ticket
                      )
                    })},[_c('label',{staticClass:"row justify-center",staticStyle:{"font-family":"'Font-principal Medium'","color":"#202020"}},[_vm._v(" "+_vm._s(_vm.$store.state.ticket.nombre_estado)+" ")])])],1):_c('q-card',{staticClass:"full-width btn-detalle"},[_c('q-card-section',{staticClass:"q-pa-sm",style:({
                      'background-color': _vm.getColor(
                        _vm.$store.state.ticket.estado_ticket
                      )
                    })},[_c('label',{staticClass:"row justify-center text-white",staticStyle:{"font-family":"'Font-principal Medium'"}},[_vm._v(" "+_vm._s(_vm.$store.state.ticket.nombre_estado)+" ")])])],1)],1)]),_c('q-card-section',[_c('div',{staticClass:"row flex-center full-width"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-12 row flex-center"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v(_vm._s(_vm.$t("request.selected-service"))+":")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(" "+_vm._s(_vm.$store.state.ticket.service.name)+" ")])]),_c('div',{staticClass:"col-12 q-py-md"},[_c('q-separator')],1),_c('div',{staticClass:"col-12 row items-center justify-between"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row justify-center q-py-sm"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v(" Creador del ticket: ")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(_vm._s(_vm.fullName))])])]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row justify-start q-py-sm"},[_c('q-separator',{staticClass:"gt-sm",attrs:{"vertical":"","inset":""}}),_c('div',{class:'col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-start' +
                            (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : ' q-pl-md')},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Fecha:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(" "+_vm._s(_vm.$store.state.ticket.date ? _vm.humanDate(_vm.$store.state.ticket.date) : '')+" ")])])])],1),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 row justify-start q-py-sm"},[_c('q-separator',{staticClass:"gt-sm",attrs:{"vertical":"","inset":""}}),_c('div',{class:'col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-start' +
                            (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : ' q-pl-md')},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v("Hora:")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(" "+_vm._s(_vm.humanTime(_vm.$store.state.ticket.date))+" ")])])])],1)]),_c('div',{staticClass:"col-12 q-py-md"},[_c('q-separator')],1),_c('div',{staticClass:"col-12 row flex-center"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v(" "+_vm._s(_vm.$t("request.ticket_detail"))+": ")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(" "+_vm._s(_vm.$store.state.ticket.message)+" ")])])]),_c('div',{staticClass:"col-12 q-py-md"},[_c('q-separator')],1),_c('div',{staticClass:"col-12 row items-center justify-between"},[(_vm.$store.state.ticket.default_address != 1)?_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row justify-center q-py-sm"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v(" "+_vm._s(_vm.$t("request.ticket_address"))+": ")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"texto_detalle"},[_vm._v(" "+_vm._s(_vm.$store.state.ticket.calle + " " + _vm.$store.state.ticket.numero)+" ")])])]):_vm._e(),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 row justify-start q-py-sm"},[(_vm.$store.state.ticket.default_address != 1)?_c('q-separator',{staticClass:"gt-sm",attrs:{"vertical":"","inset":""}}):_vm._e(),_c('div',{class:'col-xs-12 col-sm-12 col-md-11 col-lg-11 col-xl-11 row justify-start' +
                            (_vm.$q.screen.xs || _vm.$q.screen.sm ? '' : ' q-pl-md')},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v(" "+_vm._s(_vm.$t("forms.motive"))+": ")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[(_vm.$store.state.ticket.motive == 1)?_c('label',{staticClass:"texto_detalle"},[_vm._v(" Solicitud ")]):(_vm.$store.state.ticket.motive == 2)?_c('label',{staticClass:"texto_detalle"},[_vm._v(" Sugerencia ")]):(_vm.$store.state.ticket.motive == 3)?_c('label',{staticClass:"texto_detalle"},[_vm._v(" Felicitaciones ")]):(_vm.$store.state.ticket.motive == 4)?_c('label',{staticClass:"texto_detalle"},[_vm._v(" Reclamo ")]):_c('label',{staticClass:"texto_detalle"},[_vm._v("Otro")])])])],1)]),(
                      _vm.$store.state.ticket.files != null &&
                        _vm.$store.state.ticket.files.length > 0
                    )?_c('div',{staticClass:"col-12 q-py-md"},[_c('q-separator')],1):_vm._e(),(
                      _vm.$store.state.ticket.files != null &&
                        _vm.$store.state.ticket.files.length > 0
                    )?_c('div',{staticClass:"col-12 row flex-center"},[_c('div',{staticClass:"col-12 row items-center justify-start"},[_c('label',{staticClass:"titulo_texto_detalle"},[_vm._v(" "+_vm._s(_vm.$t("request.ticket_files"))+": ")])]),_c('div',{staticClass:"col-12 row items-center justify-start"},[(
                          _vm.$store.state.ticket.files != null &&
                            _vm.$store.state.ticket.files.length > 0
                        )?_c('div',{staticClass:"row files-title q-mb-sm col-12"},_vm._l((_vm.$store.state.ticket.files),function(file,index){return _c('q-btn',{key:index,staticClass:"btn no-shadow full-width q-my-sm",attrs:{"color":"principal","icon":"attach_file","label":_vm._f("truncate")(file.original,50, '...'),"dense":"","outline":"","type":"a","target":"_blank","download":""},on:{"click":function($event){return _vm.download(file.id, file.original)}}})}),1):_vm._e()])]):_vm._e(),_c('div',{staticClass:"col-12 row flex-center q-pb-md q-pt-lg"},[_c('div',{staticClass:"col-12 row flex-center"},[_c('q-btn',{staticClass:"full-width",staticStyle:{"border-radius":"7px"},attrs:{"label":_vm.$t('forms.go_home'),"color":"principal"},on:{"click":function($event){return _vm.routerGo('newrequest')}}})],1)])])])])],1)],1),_c('q-dialog',{attrs:{"seamless":"","position":"bottom"},model:{value:(_vm.downloading_file),callback:function ($$v) {_vm.downloading_file=$$v},expression:"downloading_file"}},[_c('q-card',{staticStyle:{"min-width":"350px"}},[_c('q-card-section',[_c('div',[_vm._v("Descargando Archivo")])]),_c('q-card-section',[_c('q-linear-progress',{staticClass:"q-pa-md",staticStyle:{"height":"50px"},attrs:{"color":"principal","value":_vm.download_progress}},[_c('div',{staticClass:"absolute-full flex flex-center"},[_c('q-badge',{attrs:{"color":"black","text-color":"white","label":_vm.progressLabel}})],1)])],1)],1)],1)],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }